import React, {useEffect, useState} from 'react'
import './footersharelist.scss'
import {
  FacebookShareButton,
  FacebookIcon,
	TwitterShareButton,
	TelegramShareButton,
  TwitterIcon,
  VKShareButton,
	VKIcon,
	TelegramIcon,
} from 'react-share'
const FooterShareList = props => {
	const [url, setUrl] = useState("")
	const [title, setTitle] = useState("")
	useEffect(() => {
		setUrl(window.location.href)
		setTitle(document.title)
	}, [])
	
	return (
		<div className="b-footer-share">
			<ul className="h-list-unstyled b-footer-share__list">
				<li className="b-footer-share__list-item">
					<FacebookShareButton url={url}>
						<FacebookIcon size={42} />
					</FacebookShareButton>
				</li>
        <li className="b-footer-share__list-item">
					<TwitterShareButton url={url} title={title}>
						<TwitterIcon size={42} />
					</TwitterShareButton>
				</li>
        <li className="b-footer-share__list-item">
					<VKShareButton url={url} title={title} >
						<VKIcon size={42} />
					</VKShareButton>
				</li>
        <li className="b-footer-share__list-item">
					<TelegramShareButton url={url} title={title} >
						<TelegramIcon size={36} />
					</TelegramShareButton>
				</li>
			</ul>
		</div>
	)
}


export default FooterShareList
