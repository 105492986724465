import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts'
import LessonCard from '../components/Lesson/LessonCard'
import Seo from '../components/Seo'
import Footer from '../components/Footer';

const lessonTemplate = props => {
  const { title, description, testLink, image, slug, testLinkTitle } = props.data.lesson
  const cards = props.data.lesson.cards

  const seo = {
    title,
    slug,
    description,
    image,
  }
  return <Layout currentSlug={'/' + slug}>
      <Seo {...seo} />
      <div className="l-section b-lesson">
        {/* TODO Move to lesson component */}
        <LessonCard title={title} image={image} isHeaderCard={true} />
        <ul className="b-lessons__list h-list-unstyled">
          {cards && cards.map(card => <li key={card.id} className="b-lessons__list-item">
            <LessonCard {...card} />
          </li>)}
        </ul>
        {testLink && <Link className="b-lesson__button b-button b-button--quiz" to={`/${testLink.slug}`}>
          {testLinkTitle}
        </Link>}
      </div>
      <Footer />
    </Layout>
}

export default lessonTemplate

export const pageQuery = graphql`
  query lessonQuery($id: String!) {
    lesson: contentfulLessons(id: { eq: $id }) {
      title
      description
      slug
      cards {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          id
          file {
            url
            fileName
            contentType
          }
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
            srcWebp
            srcSetWebp
          }
        }
        video
      }
      image {
        id
        file {
          url
          fileName
          contentType
        }
        fluid {
          aspectRatio
          base64
          src
          sizes
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      testLink {
        slug
        title
      }
      testLinkTitle
    }
  }
`
