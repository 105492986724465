import React from 'react'
import SubscribeForm from '../SubscribeForm'
import FooterShareList from '../FooterShareList'
import './footer.scss'

const Footer = (props) => {
	return (
		<footer className="footer">
			<div className="l-container l-container--base-width l-container--footer">
					<SubscribeForm />
					<FooterShareList />
			</div>
		</footer>
	)
}

export default Footer

