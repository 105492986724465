import React, {PureComponent} from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import './LessonCard.scss'
import Img from 'gatsby-image'
export default class LessonCard extends PureComponent {
  constructor(props)  {
    super(props)
    this.el = "";
  }

  componentDidMount = () => {
    let table = this.el && this.el.querySelector('table')
    if (table) {
      let wrapper = document.createElement('div');
      wrapper.classList.add("responsive-table-wrapper")
      table.parentNode.insertBefore(wrapper, table);
      wrapper.appendChild(table);
    }
    let links = this.el && this.el.querySelectorAll('a')
    if (links.length > 0) {
      let arr = Array.from(links)
      arr.map(link => (link.target = '_blank'))
    }
  }
  
  render() {
    const {
     title,
     description,
     image,
     video,
     isHeaderCard,
     isNkoHeaderCard,
     isNkoCard,
     hiLight,
   } = this.props
   const renderTitle = title.replace(hiLight,'')
    return <div className={cn('b-lesson__card b-lesson-card', {
      'b-lesson-card--image': image,
      'b-lesson-card--video': video,
      'b-lesson-card--header': isHeaderCard,
      'b-lesson-card--nko-header': isNkoHeaderCard,
      'b-lesson-card--nko': isNkoCard,
    })}>
      {isNkoHeaderCard 
        ? <h2 className="b-lesson-card__title t-header">
          {renderTitle} <span>{hiLight}</span>
        </h2> 
        : <h2 className="b-lesson-card__title t-header">
          {title}
        </h2>}
      {description && <div ref={el => this.el = el} dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />}
      {image && <Img className={cn('b-lesson-card__image', {
        'b-lesson-card__image--dutone': isHeaderCard,
      })} fluid={image.fluid} />}
      {video && <div className="b-lesson-card__video" dangerouslySetInnerHTML={{ __html: video }} />}
    </div>
  }
}

LessonCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  image: PropTypes.object,
  video: PropTypes.string,
  isHeaderCard: PropTypes.bool,
}

