import React, { useState } from 'react'
import './subscribeform.scss'
import cn from 'classnames'

const encode = (data) => {
	return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
}

const SubscribeForm = props => {
	const [formValue, setFormValue] = useState({})
	const [formStatus, setFormStatus] = useState('')
	const [isButtonVisible, setIsButtonVisible] = useState(false)

	const handleChange = (e) => {
		!isButtonVisible && setIsButtonVisible(true)
		e.persist();
		setFormValue(values => ({ ...values, [e.target.name]: e.target.value }));
	}

	const handleSubmit = (e) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ "form-name": "subscribers", ...formValue })
		})
			.then(() => {setFormStatus('success')})
			.catch(error => {setFormStatus(error)});
		e.preventDefault()
	}

	return (
		<form 
			action="/" 
			name="subscribers"
			className={cn("b-form b-subscribe-form", {
			  "b-subscribe-form--success": formStatus === "success",	
			})}
			data-netlify="true" 
			onChange={handleChange} 
			onSubmit={handleSubmit}
		>
			<div className="b-subscribe-form__group">
				<label htmlFor="sbs-email" className="b-label b-subscribe-form__label"> Подпишитесь на обновления Медиарешебника</label>
				<input 
					placeholder="e-mail"
					className="b-input b-subscribe-form__input"
					type="email" 
					required
					name="subscriber-email" 
					id="sbs-email" 
				/>
				<button 
					className={cn("b-button b-subscribe-form__button b-button--primary")} 
					type="submit">Подписаться</button>
			</div>
		</form>
	)
}

export default SubscribeForm
